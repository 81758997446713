<template>
  <div class="page-container">
    <div class="donate-form-box">
      <h2>捐赠信息</h2>
      <van-field label="项目名称" :value="projectName" readonly />
      <van-field label="捐赠金额" :value="donateAmount" readonly />
      <van-field
        label="实物认捐"
        v-if="thingsDisplay"
        :value="thingsDisplay"
        readonly
      />
      <van-field v-if="isgroup" label="捐赠团队" :value="groupName" readonly>
        <template #button>
          <van-checkbox v-model="joinGroup" @change="handleJoinGroup" />
        </template>
      </van-field>
      <van-field
        v-else
        readonly
        clickable
        name="picker"
        :value="pickedGroupName"
        label="捐赠团队"
        placeholder="参加捐赠团队"
        @click="showGroupPicker = true"
      />
      <van-popup v-model="showGroupPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="groupPickerColumns"
          @confirm="handleGroupPicker"
          @cancel="showGroupPicker = false"
        />
      </van-popup>
      <van-field
        v-model="donate.message"
        rows="2"
        autosize
        label="捐赠留言"
        type="textarea"
        maxlength="30"
        placeholder="请输入留言"
        show-word-limit
      >
        <template #button>
          <van-button
            size="small"
            @click="randomMessage"
            plain
            hairline
            type="primary"
            >随机</van-button
          >
        </template>
      </van-field>
    </div>
    <div class="donate-form-box" v-if="!donate.annoymous">
      <h2>捐赠方</h2>
      <van-field name="radio" label="捐赠方性质">
        <template #input>
          <van-radio-group v-model="donate.donor_type" direction="horizontal">
            <van-radio :name="1">个人</van-radio>
            <van-radio :name="2">团体</van-radio>
            <van-radio :name="3">企业</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field v-model="donate.donor_name" label="捐赠方名称" required />
      <van-field v-model="donate.donor_phone" type="tel" label="联系电话" />
      <van-field
        readonly
        clickable
        name="picker"
        :value="donate.donor_relation"
        label="与学校关系"
        placeholder="点击选择"
        @click="showRelationPicker = true"
      />
      <template v-if="donate.donor_relation == '校友'">
        <van-field
          readonly
          clickable
          name="picker"
          :value="donate.donor_exp"
          label="在校经历"
          placeholder="点击选择"
          @click="showExpPicker = true"
        />
        <van-field
          v-model="donate.donor_graduate"
          type="number"
          label="入校年份"
        />
        <van-field v-model="donate.donor_major" label="专业" />
      </template>
      <template v-if="donate.donor_relation == '职工'">
        <van-field v-model="donate.donor_job" label="部门" />
        <van-field v-model="donate.donor_unit" label="工作单位" />
      </template>
      <template v-if="donate.donor_relation == '友人'">
        <van-field v-model="donate.donor_unit" label="工作单位" />
      </template>
      <van-field
        readonly
        clickable
        name="picker"
        :value="donate.donor_college"
        label="院系"
        placeholder="选择您的学院"
        @click="showCollegePicker = true"
      />
    </div>
    <!--div class="donate-form-box">
      <h2>其他</h2>
      <van-field name="radio" label="发票">
        <template #input>
          <van-radio-group v-model="donate.need_ticket" direction="horizontal">
            <van-radio :name="0">不需要</van-radio>
            <van-radio v-if="false" :name="1">电子发票</van-radio>
            <van-radio :name="2">纸质发票</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </div-->
    <van-button
      style="width: 100%"
      @click="handleSubmitForm"
      type="primary"
      :disabled="isCreatingOrder"
      >{{ isCreatingOrder ? "正在创建订单" : "下一步" }}</van-button
    >
    <van-popup v-model="showRelationPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="relationList"
        @confirm="handleRelationPicker"
        @cancel="showRelationPicker = false"
      />
    </van-popup>
    <van-popup v-model="showExpPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="expList"
        @confirm="handleExpPicker"
        @cancel="showExpPicker = false"
      />
    </van-popup>
    <van-popup v-model="showCollegePicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="collegeList"
        @confirm="handleCollegePicker"
        @cancel="collegeExpPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  getCollegeList,
  createDonateOrder,
  getMessageTemplate,
} from "@/api/donate";
import { getProjectTitle } from "@/api/project";
import { getGroupName, getTinyGroupList } from "@/api/group";

import { Notify } from "vant";
export default {
  name: "DonateForm",
  data() {
    return {
      donate: {
        pid: null,
        group: null,
        group_id: null,
        amount: 0.0,
        annoymous: false,
        message: null,
        donor_type: 1,
        donor_name: null,
        donor_phone: null,
        donor_relation: null,
        pay_type: 1,
        donor_exp: null,
        donor_job: null,
        donor_unit: null,
        donor_college: null,
        need_ticket: 0,
        inviter: null,
        selected: [],
      },
      showRelationPicker: false,
      relationList: ["校友", "职工", "友人"],
      showExpPicker: false,
      expList: ["本科", "硕士", "博士", "教工", "专科", "继续教育"],
      showCollegePicker: false,
      collegeList: [],
      projectName: null,
      donateAmount: null,
      groupName: null,
      isgroup: false,
      thingsDisplay: "",
      randomMessageList: [],
      joinGroup: true,
      showGroupPicker: false,
      groupList: [],
      groupPickerColumns: [null],
      pickedGroupName: null,
      isCreatingOrder: false,
    };
  },
  mounted() {
    this.donate.annoymous = parseInt(this.$route.query.annoymous);
    this.donate.pid = parseInt(this.$route.query.pid);
    this.donate.amount = parseFloat(this.$route.query.amount);
    this.donateAmount = `￥${this.donate.amount}`;
    if (this.$route.query.group !== undefined) {
      this.isgroup = true;
      this.donate.group_id = parseInt(this.$route.query.group);
    }
    if (this.$route.query.inviter !== undefined) {
      this.donate.inviter = parseInt(this.$route.query.inviter);
    }
    if (this.$route.query.selected !== undefined) {
      this.donate.selected = JSON.parse(this.$route.query.selected);
      this.donate.selected.forEach((item) => {
        this.thingsDisplay += `${item.name}x${item.selected};`;
      });
    }
    this.fetchData();
  },

  methods: {
    fetchData() {
      getCollegeList().then((response) => {
        this.collegeList = response;
        this.collegeList.unshift(null);
      });
      getProjectTitle(this.donate.pid).then((response) => {
        this.projectName = response.title;
      });
      getTinyGroupList(this.donate.pid).then((response) => {
        this.groupList = response;
        this.groupList.forEach((group) => {
          this.groupPickerColumns.push(group.name);
        });
      });
      if (this.isgroup) {
        getGroupName(this.donate.group_id).then((response) => {
          this.groupName = response.name;
        });
      }
      getMessageTemplate(this.donate.pid).then((response) => {
        this.randomMessageList = response;
      });
    },
    handleRelationPicker(value) {
      this.donate.donor_relation = value;
      this.showRelationPicker = false;
    },
    handleExpPicker(value) {
      this.donate.donor_exp = value;
      this.showExpPicker = false;
    },
    handleCollegePicker(value) {
      // TODO:
      // this.donor_college_name = value
      // let index = this.collegeList.indexOf(value)
      this.donate.donor_college = value;
      this.showCollegePicker = false;
    },
    handleSubmitForm() {
      if (this.donate.annoymous === 0 && this.donate.donor_name === "") {
        Notify({ type: "warning", message: "请填写捐赠方名称" });
        return;
      }
      this.isCreatingOrder = true;
      if (this.donate.group_id && this.joinGroup) {
        this.donate.group = this.donate.group_id;
      } else if (this.pickedGroupName) {
        let groupListTemp = [];
        this.groupList.forEach((item) => {
          groupListTemp.push(item.name);
        });
        let sid = groupListTemp.indexOf(this.pickedGroupName);
        if (sid === -1) {
          this.donate.group = null;
        } else {
          this.donate.group = this.groupList[sid].id;
        }
      } else {
        this.donate.group = null;
      }

      console.log(this.donate);

      createDonateOrder(this.donate)
        .then((response) => {
          window.location.href = response.link;
          // this.$router.push({ path: `/order/${response.order}` });
        })
        .catch(() => {
          this.isCreatingOrder = false;
        });
    },
    randomMessage() {
      this.donate.message = this.randomMessageList[
        Math.floor(Math.random() * this.randomMessageList.length)
      ];
    },
    handleJoinGroup(val) {
      this.joinGroup = val;
    },
    handleGroupPicker(val) {
      this.pickedGroupName = val;
      this.showGroupPicker = false;
    },
  },
};
</script>

<style>
.donate-form-box {
  margin-bottom: 0.1rem;
  background-color: #ffffff;
}

.donate-what-title {
  min-width: 1rem !important;
  width: 1rem;
  text-align: left;
}

.donate-what-value {
  text-align: left;
}

.donate-form-box h2 {
  font-size: 1.2rem;
  text-align: left;
  margin: 0 1rem;
  padding: 0.5rem 0;
}

.pay-icon {
  font-size: 24px;
  margin-right: 1rem;
}
</style>
